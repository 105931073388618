import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import Layout from "Layouts/default"
import Config from "Data"
import { Utils, SEO, FeaturedSection } from "Components"
import { graphql } from "gatsby"
import { Title, Subtitle, StyledLink } from "Styles"
import Gap from "styles/Gap"

const TextWrapper = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  text-align: center;
`

const ContactWrapper = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
`

class Launch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      params: null,
      form: null,
      typeform: null,
      email: null,
      name: null,
      ext: null,
    }
  }

  componentDidMount() {
    const paramsObj = Utils.getWindowParamsObj()
    const params = Object.keys(paramsObj)
      .map((key) => key + "=" + paramsObj[key])
      .join("&")
    const rep = paramsObj.rep
    const form = paramsObj.form
    this.setState({
      params: params,
      form: form,
      typeform: Config.typeform[form],
      name: Config.poc[rep?.toLowerCase()]?.name,
      email: Config.poc[rep?.toLowerCase()]?.email,
      ext: Config.poc[rep?.toLowerCase()]?.ext,
    })
  }

  render() {
    const { data } = this.props
    const featured = this.state.form === "webinar" ? data.bgWebinars.edges[0].node : data.bgInPerson.edges[0].node
    const seo =
      this.state.form === "webinar" || this.state.form === "inperson"
        ? `Private Events | Client Launch | ${Config.brandBusiness} by ${Config.contact.name}`
        : `CXOsync | Client Launch | ${Config.brandBusiness} by ${Config.contact.name}`
    const needSomeHelp =
      this.state.form === "webinar"
        ? "Please reach out as I will be your designated Rep throughout planning, population and execution of your webinar."
        : "Please reach out as I will be your designated Rep throughout planning, population and execution of your event."
    return (
      <Layout
        locale={"en-us"}
        region={"us"}
        simple={false}
        sticky={false}
        transparentHeader={false}
        transparentFooter={true}>
        <SEO pathname={`/sponsorship/launch?`} title={seo} description={``} />
        <Helmet>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <Gap />
        <Gap />
        <Gap />
        <Gap />
        <TextWrapper>
          <Title>Let's get started!</Title>
        </TextWrapper>
        <br />
        <FeaturedSection
          dark={false}
          title={`Your Event Page`}
          subtitle={`We need a few details so we can start on your draft event page. Click below to breeze through these details
            which include your contact info (as the designated Point of Contact), marketing collateral like logos and
            descriptions so we can theme out your event, target audience so we can make it relevant and appealing to
            your audience and any special notes you feel will help tell your story.`}
          image={featured.childImageSharp.gatsbyImageData || ""}
          cta_url2_text={"Start here"}
          cta_url2={
            this.state.typeform
              ? `https://cxo.typeform.com/to/${this.state.typeform}/?rep=${this.state.name}&ext=${this.state.ext}&email=${this.state.email}`
              : null
          }
          track={"Start here"}
        />
        <Gap />
        {this.state.name && (
          <span>
            <TextWrapper>
              <Title>Need some help!</Title>
            </TextWrapper>
            <TextWrapper>
              <Subtitle>{needSomeHelp}</Subtitle>
            </TextWrapper>
            <ContactWrapper>
              <br />
              <Title>{this.state.name}</Title>
              <StyledLink to={`mailto:${this.state.email}`} title={`Connect with ${this.state.name}`} target={`_blank`}>
                {this.state.email}
              </StyledLink>
              <br />
              <Subtitle>+1 (847) 278-2213 {this.state.ext ? "ext: " + this.state.ext : ""}</Subtitle>
            </ContactWrapper>
          </span>
        )}
      </Layout>
    )
  }
}

export default Launch

export const query = graphql`
  query LaunchQuery {
    bgInPerson: allFile(filter: { name: { eq: "bg-inperson" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, transformOptions: { fit: CONTAIN })
          }
        }
      }
    }
    bgWebinars: allFile(filter: { name: { eq: "bg-webinars" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, transformOptions: { fit: CONTAIN })
          }
        }
      }
    }
    triangle: allFile(filter: { name: { eq: "triangle" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(width: 2880, placeholder: BLURRED)
          }
        }
      }
    }
    webinar: allFile(filter: { extension: { regex: "/(jpg)/" }, name: { eq: "bg-webinars" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(width: 2880, placeholder: BLURRED)
          }
        }
      }
    }
    inperson: allFile(filter: { extension: { regex: "/(jpg)/" }, name: { eq: "bg-inperson" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(width: 2880, placeholder: BLURRED)
          }
        }
      }
    }
  }
`
